body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #2D7ACB, #7B5FC9);
    color: #ffffff;
    overflow-x: hidden;
    animation: fadeIn 1s ease-out;
}

/* Fade-in animation for body */
@keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
}

h2 {
    font-size: 36px;
    margin: 20px 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    animation: slideIn 0.8s ease-out;
}

/* Slide-in animation for h2 */
@keyframes slideIn {
    0% { opacity: 0; transform: translateX(-30px); }
    100% { opacity: 1; transform: translateX(0); }
}

a {
    color: #ff5722;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    position: relative;
}

/* Hover effect for links */
a:hover {
    color: #fff;
    transform: translateY(-5px) scale(1.1);
    text-shadow: 0 4px 8px rgba(255, 87, 34, 0.5);
}

a:active {
    transform: translateY(1px);
    color: #ff5722;
}

/* Global transition for all elements */
* {
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

/* Button hover effect */
button {
    padding: 10px 20px;
    background: #2D7ACB;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.4s ease;
}

button:hover {
    background: #1f5b8c;
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Button focus effect */
button:focus {
    outline: none;
    box-shadow: 0 0 5px 3px rgba(0, 255, 255, 0.5);
}

/* Background animation */
@keyframes bgAnimation {
    0% { background: linear-gradient(135deg, #2D7ACB, #7B5FC9); }
    50% { background: linear-gradient(135deg, #7B5FC9, #2D7ACB); }
    100% { background: linear-gradient(135deg, #2D7ACB, #7B5FC9); }
}

body {
    animation: bgAnimation 5s ease infinite;
}

/* Hover effect for images */
img {
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease;
}

img:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

/* Card hover effect */
.card {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

/* Pulse animation */
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.pulse {
    animation: pulse 1.5s ease-in-out infinite;
}

/* Parallax effect */
.parallax {
    position: relative;
    background: url('https://via.placeholder.com/1500') no-repeat center center fixed;
    background-size: cover;
    height: 400px;
    transition: transform 0.3s ease-in-out;
}

.parallax:hover {
    transform: scale(1.1);
}

/* Shadow effects for elements */
.shadow-effect {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
}

.shadow-effect:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

/* Sliding text animation */
@keyframes slideText {
    0% { opacity: 0; transform: translateX(-100%); }
    100% { opacity: 1; transform: translateX(0); }
}

.text-slide {
    animation: slideText 0.8s ease-out;
}

/* Rotate effect */
@keyframes rotate {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(15deg); }
    100% { transform: rotate(0deg); }
}

.rotate {
    animation: rotate 1s ease infinite;
}

/* Text shadow effect on hover */
h1, h2, h3 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

h1:hover, h2:hover, h3:hover {
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

/* Element Fade-in on Scroll */
@keyframes fadeInOnScroll {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.fade-in-on-scroll {
    opacity: 0;
    animation: fadeInOnScroll 1s ease-out forwards;
}

.fade-in-on-scroll:nth-child(odd) {
    animation-delay: 0.5s;
}

.fade-in-on-scroll:nth-child(even) {
    animation-delay: 1s;
}

/* Transition for forms */
input, textarea {
    padding: 12px 16px;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #ff5722;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    transition: border 0.3s ease, background 0.3s ease;
}

input:focus, textarea:focus {
    border-color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

/* Hover glow effect on icons */
.icon {
    transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
    transform: scale(1.2);
    color: #ff5722;
}

/* Bouncing animation for icons */
@keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

.icon-bounce {
    animation: bounce 0.6s ease-in-out infinite;
}

/* Hover effects for buttons with icons */
.button-with-icon {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.button-with-icon:hover {
    transform: scale(1.05);
    color: #ff5722;
}

/* Animation for card pop-out */
@keyframes popOut {
    0% { transform: scale(1); }
    100% { transform: scale(1.1); }
}

.card-pop-out {
    animation: popOut 0.4s ease-out;
}

/* Hover effect for navigation links */
.nav-link {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.nav-link:hover {
    color: #ff5722;
    text-shadow: 2px 2px 8px rgba(255, 87, 34, 0.7);
}

/* Typing effect for titles */
@keyframes typing {
    0% { width: 0; }
    100% { width: 100%; }
}

.typing-effect {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    border-right: 3px solid #ff5722;
    animation: typing 3s steps(30) 1s forwards;
}

/* Hover transition for images with zoom effect */
img:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
}
